<template>
    <div>
        <div v-if="is_show == 0">
            <div class="ExamListText">
                成绩查询
            </div>
            <div class="formBox">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">

                    <el-form-item label="姓名 ：" prop="title">
                        <el-input v-model="ruleForm.title" placeholder="请输入您的姓名"></el-input>
                    </el-form-item>

                    <el-form-item label="身份证号 ：" prop="idcard">
                        <el-input v-model="ruleForm.idcard" placeholder="请输入您的身份证号"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="Submit" @click="ScoreQuery('ruleForm')">
                成绩查询
            </div>
        </div>
        <div v-if="is_show == 1">
            <div class="ExamListText">
                成绩查询
            </div>
            <div class="ResultList" v-for="(item, index) in List" :key="index">
                <div>
                    <img :src="item.cover_src" v-if="item.cover_src" width="100%" height="100%" alt="">
                    <img src="@/../public/image/examintion.png" v-else width="100%" height="100%" alt="">
                </div>
                <div>
                    <div>
                        <p class="fs18" style="width:316px">{{ item.examination_title }}</p>
                    </div>

                    <div>
                        <p class="fs14">考试专业：{{ item.major_name }}</p>
                        <p class="fs14">报名时间：{{ item.join_star_time }}-{{ item.join_end_time }}</p>
                        <p class="fs14"> 考试时间：{{ item.examination_star_time }}-{{ item.examination_end_time }}</p>
                    </div>
                </div>
                <div @click="getScoreQueryDetailFn(item.examination_id)">
                    查看成绩
                </div>
            </div>

        </div>
        <div v-if="is_show == 2">
            <div class="ExamListText">
                成绩查询
            </div>
            <p class="fs24 mt20">个人信息</p>
            <p class="fs20 mt20"><span class="ml20">姓名：{{ student_info.student_name }}</span> <span class="ml20">身份证号：{{
                student_info.idcard
            }}</span>
            </p>
            <p class="fs24 mt20">成绩信息</p>
            <p class="fs20 mt20"><span class="ml20">总分：{{
                student_info.professional_achievements +
                student_info.objective_score
            }}</span><span class="ml20">笔试成绩：{{
    student_info.objective_score
}}</span>
                <span class="ml20">专业成绩：{{ student_info.professional_achievements }}</span>
            </p>
            <div class="userInfoBox" v-if="evaluate_list.length">
                <div class="userInfo" v-for="(item, index) in evaluate_list" :key="index">
                    <div>
                        <img :src="item.avatar" width="100%" height="100%" alt="" v-if="item.avatar">
                        <img v-else src="@/../public/image/touxiang.png" alt="" width="100%">
                    </div>
                    <div>
                        <div>
                            <p>评委姓名：{{ item.realname }}</p>
                            <p class="mt10 yichu">评委简介：{{ item.intro }}</p>
                        </div>
                        <div>
                            <p>评委评分：{{ item.fraction }}</p>
                            <p class="mt10">评委评语：{{ item.comment }}</p>
                        </div>

                    </div>
                </div>
            </div>

            <div class="Submit" @click="ApplyCertificate">
                申领证书
            </div>
        </div>
        <div v-if="dialog == 1" class="dialogBox">
            <!--  查询失败 -->
            <el-dialog title="提示" :visible.sync="dialogVisible">
                <div class="dialogTitle">
                    <p class="fs22">没有查询到您的信息，请检查输入内容是否正确。</p>
                </div>

                <div class="btnBox mt45">
                    <div class="complete" @click="dialog = 0">关闭</div>
                </div>
            </el-dialog>
        </div>
        <div v-if="dialog == 2" class="dialogBox">
            <!--  申领成功 -->
            <el-dialog title="提示" :visible.sync="dialogVisible">
                <div class="dialogTitle">
                    <p class="fs22">请邮寄一张小二寸红底纸质照片并附带姓名身份证号。</p>
                    <p class="fs22 mt20">协会地址：{{ Address }}</p>
                </div>
                <div class="btnBox mt45">
                    <div class="complete" @click="dialog = 0">关闭</div>
                </div>

            </el-dialog>
        </div>


    </div>
</template>

<script>
import { getScoreQueryList, getScoreQueryDetail, getAddress } from '@/api/ScoreQuery'
export default {
    data() {
        return {
            dialogVisible: true,
            is_show: 0,
            dialog: 0,
            ruleForm: {
                type: 2,
                title: '',
                idcard: ""
            },
            value: '',
            List: [],
            evaluate_list: [],
            student_info: {},
            Address: "",
            rules: {
                title: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                idcard: [
                    { required: true, message: '请输入身份证号', trigger: 'blur' },
                ],
            }
        }
    },
    methods: {
        //成绩查询
        ScoreQuery(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    getScoreQueryList(this.ruleForm).then(res => {
                        if (res.code == 200) {
                            this.is_show = 1
                            this.$message.success('查询成功');
                            this.List = res.data.data
                            this.List.forEach(item => {
                                item.examination_star_time = this.$commit.timestampToTime(item.examination_star_time)
                                item.join_star_time = this.$commit.timestampToTime(item.join_star_time)
                                item.examination_end_time = this.$commit.timestampToTime(item.examination_end_time)
                                item.join_end_time = this.$commit.timestampToTime(item.join_end_time)
                            })
                        } else {
                            this.dialog = 1
                            // this.$message.error('');
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //申领证书
        ApplyCertificate() {
            this.$message.success('申领成功');
            this.dialog = 2
        },
        //获取成绩详情
        getScoreQueryDetailFn(id) {
            getScoreQueryDetail({ examination_id: id }).then(res => {
                if (res.code == 200) {
                    // this.$message.success('申领成功'); 
                    this.is_show = 2
                    this.student_info = res.data.student_info
                    this.evaluate_list = res.data.evaluate_list
                } else if (res.code == 400) {
                    this.$message.error(res.msg)
                }

            })
        },
        //获取地址
        getAddressFn() {
            getAddress().then(res => {
                this.Address = res.data[0]
            })
        }
    },
    mounted() {
        let that = this
        history.pushState(null, null, document.URL);
        window.addEventListener('popstate', function () {
            if (that.is_show <= 0) {
                that.is_show = 0
            } else {
                that.is_show -= 1
            }
            history.pushState(null, null, document.URL);
        });
        this.getAddressFn()

    },
    watch: {
        dialogVisible(val) {
            if (val == false) {
                this.dialog = 0
                this.dialogVisible = true
            }
        }
    }
}
</script>

<style  scoped>
.ExamListText {
    justify-content: center;
    color: #CD0000;
    display: flex;
    align-items: center;
    font-size: 22px;
}

.ExamListText::before {
    content: '';
    width: 80px;
    display: inline-block;
    margin-right: 20px;
    height: 2px;
    background: #CD0000;
}

.ExamListText::after {
    content: '';
    width: 80px;
    display: inline-block;
    height: 2px;
    margin-left: 20px;
    background: #CD0000;
}

.formBox {
    width: 80%;
    margin: 20px auto;
}

.formBox>>>.el-form-item__label {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
}

.formBox>>>input {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
}

.formBox>>>.el-input__inner {
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #D6D6D6;
    border-radius: 4px;
}

.formBox>>>.el-textarea__inner {
    height: 178px;
    background: #FFFFFF;
    border: 1px solid #D6D6D6;
    border-radius: 4px;
}

.Submit {
    width: 440px;
    height: 48px;
    background: #CC0000;
    cursor: pointer;
    border-radius: 8px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    margin: 100px auto;
    line-height: 48px;
    margin-bottom: 0px;
}

.ResultList {
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    border-bottom: 1px solid #cdcdcd;
}

.ResultList>div:nth-child(1) {
    width: 220px;
    height: 160px;
    background: #CD0000;
}

.ResultList>div:nth-child(2) {
    display: flex;
    box-sizing: border-box;
    padding: 10px;
    width: 336px;
    height: 160px;
    flex-wrap: wrap;
    align-content: space-between;
}

.ResultList>div:nth-child(2) p {
    width: 100%;
}

.ResultList>div:nth-child(3) {
    width: 110px;
    height: 38px;
    background: #CD0000;
    border-radius: 4px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 38px;
    text-align: center;
    margin-left: auto;
    margin-top: auto;
    cursor: pointer;
}

.dialogBox>>>.el-dialog__title {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
}

.dialogBox>>>.el-dialog {
    width: 720px;
    background: #FFFFFF !important;
    border-radius: 10px !important;
}


.dialogBox>>>.el-dialog__header {
    width: 720px;
    box-sizing: border-box;
    height: 66px;
    border-radius: 10px 10px 0 0;
    background: #F5F5F5;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.2);
}

.userInfoBox {
    width: 812px;
    background: #FFFFFF;
    border: 1px solid #BEBEBE;
    border-radius: 8px;
    margin: 20px auto;
    padding: 20px;
    box-sizing: border-box;
}

.userInfo {
    width: 812px;
    margin: 30px 0;
    display: flex;
    box-sizing: border-box;
}

.userInfo>div:nth-child(1) {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    overflow: hidden;
}

.userInfo>div:nth-child(2) {
    width: 653px;
    height: 100%;
    margin-left: 20px;
    display: flex;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    flex-wrap: wrap;
    align-content: space-between;
}

.userInfo>div:nth-child(2) div {
    width: 100%;
}

.btnBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.complete {
    width: 440px;
    height: 48px;
    background: #CC0000;
    border-radius: 8px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    line-height: 48px;
}

.yichu {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

}
</style>