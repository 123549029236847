import service from '@/utils/request2'

//查询成绩列表
export function getScoreQueryList(params){
    return service({
        url:'api/api/examination/list',
        method:'get',
        params
    })
}
export function getScoreQueryDetail(params){
    return service({
        url:'api/api/examination/achievementInfo',
        method:'get',
        params
    })
}
//获取地址
export function getAddress(){
    return service({
        url:'api/api/curriculum/getAddress',
        method:'get',
    })
}

